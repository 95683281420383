:root {
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  --text-primary: #b6b6b6;
  --text-secondary: #ececec;
  --bg-primary: #23232e;
  --bg-secondary: #141418;
  --icon-primary: #99ccff;
  --icon-secondary: #22577a;
  --transition-speed: 600ms;
  --site-palette-1: #ffffff;
  --site-palette-2: #f7fbfc;
  --site-palette-3: #b9d7ea;
  --site-palette-4: #22577a;
  --site-palette-5: #503047;
}

body {
  color: var(--site-palette-5);
  background-color: var(--site-palette-1);
  margin: 0;
  padding: 0;
  transition: var(--transition-speed);
}

  body::-webkit-scrollbar {
      width: 0.25rem;
  }

  body::-webkit-scrollbar-track {
      background: var(--site-palette-5);
  }

  body::-webkit-scrollbar-thumb {
      background: var(--site-palette-4);
  }

.main-content {
    margin-left: 5rem;
    padding: 1rem;
}

/* Small screens */
@media only screen and (max-width: 600px) {
    .main-content {
        margin: 0;
    }
}

h2 {
  color: var(--site-palette-4);
  transition: var(--transition-speed);
}

.wishListItemsTable {
  color: var(--site-palette-5);
  transition: var(--transition-speed);
} .wishListItemsTable a {
  color: var(--site-palette-4);
  transition: var(--transition-speed);
}

    /* Provide sufficient contrast against white background */
    /*a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}*/