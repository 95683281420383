.sidenav {
    position: fixed;
    background-color: var(--bg-primary);
    transition: width 200ms ease;
}

.sidenav-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.nav-item {
    width: 100%;
}

    .nav-item:last-child {
        margin-top: auto;
    }

.sidenav-link {
    display: flex;
    align-items: center;
    height: 5rem;
    color: var(--text-primary);
    text-decoration: none;
    filter: grayscale(100%) opacity(0.7);
    transition: var(--transition-speed);
}

    .sidenav-link:hover {
        filter: grayscale(0%) opacity(1);
        background: var(--bg-secondary);
        color: var(--text-secondary);
        text-decoration: none;
    }

.link-text {
    display: none;
    margin-left: 1rem;
}

.sidenav-link svg {
    max-width: 2rem;
    min-width: 2rem;
    margin: 0 1.5rem;
}

.fa-primary {
    color: var(--icon-primary);
    /*color: #ff9933;*/
    /*color: #ff7eee;*/
}

.fa-secondary {
    color: var(--icon-secondary);
    /*color: #ff3333;*/
    /*color: #df49a6;*/
}

.fa-primary, .fa-secondary {
    transition: var(--transition-speed);
}

.logo {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
    text-align: center;
    color: var(--text-secondary);
    background: var(--bg-secondary);
    font-size: 1.5rem;
    letter-spacing: 0.3ch;
    width: 100%;
}

    .logo svg {
        transform: rotate(0deg);
        transition: var(--transition-speed);
    }

.sidenav:hover .logo svg {
    transform: rotate(-180deg);
}

/* Small screens */
@media only screen and (max-width: 600px) {
    .sidenav {
        bottom: 0;
        width: 100vw;
        height: 5rem;
    }

    .logo {
        display: none;
    }

    .sidenav-nav {
        flex-direction: row;
    }

    .sidenav-link {
        justify-content: center;
    }
}

@media only screen and (min-width: 600px) {
    .sidenav {
        width: 5rem;
        height: 100vh;
        top: 0;
    }

        .sidenav:hover {
            width: 12rem;
        }

            .sidenav:hover .link-text {
                display: inline;
                transition: opacity var(--transition-speed);
            }
}